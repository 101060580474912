
import './pfngt.css'

const Pfngt = () => {

    return (
        <>
            <div >
                can tight shoulders affect breathing
                <ol>
                    <li> <a href='https://www.healthline.com/health/breathe-deeper-improve-health-and-posture#takeaway'> https://www.healthline.com/health/breathe-deeper-improve-health-and-posture#takeaway</a></li>
                    <li> <a href='https://www.medicalnewstoday.com/articles/chest-pain-when-bending-forward'> https://www.medicalnewstoday.com/articles/chest-pain-when-bending-forward</a></li>
                </ol>
                <br />
                <a href='https://www.youtube.com/watch?v=sBJkGoScFjM'> Got pain while working at computer</a>
                <br />
                <a href='https://www.youtube.com/watch?v=cHA_lQz8SIw'> 10 Habits Causing Your Pain</a>
                <br />
                <a href='https://www.youtube.com/watch?v=TuoDD7lm9AE'> Are Your Chest Muscles Tight (Pec Major or Minor) How To Tell</a>
                <br />
                <a href='https://www.youtube.com/watch?v=2oXoiQfija4'>3 Breathing Exercises That Can Change Your Life!</a>
                <br />
                <a href='https://www.youtube.com/watch?v=_QTJOAI0UoU'>Change Your Breath, Change Your Life | Lucas Rockwood </a>
                <br />
                <a href='https://www.youtube.com/watch?v=hFcQpNr_KA4'>Breath -- five minutes can change your life | Stacey Schuerman</a>


            </div>


            <div>
                
                  <h1>Top AI/ML Experts</h1>
                
                  <h2>Deep Learning</h2>
                  <ul>
                    <li>Sebastian Raschka, PhD, author of well-known "Build a Large Language Model from Scratch".</li>
                    <li>Andrew Ng - founder of DeepLearning.AI a platform filled with high-quality learning resources for free.</li>
                    <li>Karun Thankachan - Senior Data Scientist at Walmart and RecSys researcher.</li>
                  </ul>
                
                  <h2>Machine Learning</h2>
                  <ul>
                    <li>Chip Huyen - author of "Designing Machine Learning Systems"(O'Reilly 2022) and recently my favorite "AI engineering"</li>
                    <li>Aurimas Griciūnas, founder of Neptune.ai, focusing on MLOps solutions for machine learning projects.</li>
                    <li>Santiago Valdarrama, founder at Tideily and ML educator on Youtube.</li>
                    <li>Krish Naik, educator who provides tutorials on ml and deep learning with real-world scenarios.</li>
                  </ul>
                
                  <h2>ML and AI Engineering</h2>
                  <ul>
                    <li>Aishwarya Naresh Reganti, tech lead at AWS and educator on LLMs and GenAI.</li>
                    <li>Andriy Burkov, author of the "100 days of ML" book.</li>
                    <li>Pau Labarta Bajo, real-world ML systems educator and content creator.</li>
                  </ul>
                
                  <h2>AI Product Management</h2>
                  <ul>
                    <li>Marily Nika, Ph.D, Gen AI product lead at Google and AI Product Management Educator.</li>
                    <li>Allie K. Miller, US Head of AI Business Development for Startups at Amazon, known for her contributions to AI product strategy</li>
                  </ul>
                
                  <h2>MLops or LLMops</h2>
                  <ul>
                    <li>Maria Vechtomova, MLops tech lead and Databricks beacon.</li>
                    <li>Raphaël Hoogvliets, Tech Lead ML engineering and content creator.</li>
                    <li>Paul Iusztin, Founder at DecodingML and educator.</li>
                  </ul>
                
                  <h2>Developer Advocacy</h2>
                  <ul>
                    <li>Megan Lieu, Linkedin content creator on Data Science career.</li>
                    <li>Sonam G. G., Podcast host and Community leader.</li>
                    <li>Aishwarya Srinivasan, Senior AI advisor and Linkedin Top Voice.</li>
                  </ul>

                    <a href="https://www.linkedin.com/posts/meri-nova_machinelearning-activity-7287914427822088192-guip?utm_source=share&utm_medium=member_desktop"> Click here for source </a>


            </div>

        </>
    )
}

export default Pfngt;
