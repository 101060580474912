import { useNavigate } from 'react-router-dom';

import './healthHome.css'

const HealthHome = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className='heading-1'>"Health is the ultimate negative idea, You don’t think about it until you lose it."</div>
            <div onClick={() => navigate(-1)}> go back</div>

            <div>
                <h1>Autophagy</h1>
                
                <p>Autophagy (from Greek "auto" meaning self, and "phagy" meaning eating) is the body's cellular cleaning process. It involves the breakdown and recycling of damaged organelles, proteins, and other cellular components.</p>
                
                <h3>Triggering Autophagy:</h3>
                <p>Fasting is one of the most effective ways to trigger autophagy. It is typically activated during periods of nutrient deprivation, forcing cells to find internal sources of energy.</p>
                
                <h3>Benefits of Autophagy:</h3>
                <ul>
                  <li>Removes dysfunctional cells, reducing the risk of diseases like cancer.</li>
                  <li>Protects against neurodegenerative diseases (e.g., Alzheimer's, Parkinson's).</li>
                  <li>Improves immune system function by eliminating old immune cells.</li>
                  <li>Enhances longevity and overall cellular health.</li>
                </ul>
                
                <h2>2. Phases of Fasting and Their Impact on the Body</h2>
                
                <h3>0-12 Hours: Postprandial Phase</h3>
                <ul>
                  <li>The body uses glucose from food for energy.</li>
                  <li>Insulin levels are elevated, and autophagy is minimal.</li>
                </ul>
                
                <h3>12-24 Hours: Transition to Fat Burning</h3>
                <ul>
                  <li>Glycogen stores in the liver deplete.</li>
                  <li>The body begins lipolysis (breaking down fats into fatty acids and ketones).</li>
                  <li>Early signs of autophagy may start, particularly if glycogen levels are low.</li>
                </ul>
                
                <h3>24-72 Hours: Enhanced Fat Burning and Autophagy</h3>
                <ul>
                  <li>Ketogenesis becomes the primary source of energy.</li>
                  <li>Autophagy increases significantly, especially after 24 hours.</li>
                  <li>Growth hormone levels rise, promoting muscle preservation and fat utilization.</li>
                </ul>
                
                <h3>Beyond 72 Hours: Cellular Regeneration</h3>
                <ul>
                  <li>Autophagy peaks and may lead to significant cellular cleanup.</li>
                  <li>Stem cell production increases, supporting regeneration.</li>
                  <li>Benefits plateau after prolonged fasting, and care should be taken to avoid nutrient deficiencies.</li>
                </ul>
                
                <h2>What it does</h2>
                
                <ul>
                  <li><b>Cell recycling:</b> Autophagy breaks down and removes damaged organelles, proteins, and other substances from a cell's cytoplasm.</li>
                  <li><b>Energy balance:</b> Autophagy helps balance energy sources when a cell is stressed or starved.</li>
                  <li><b>Cell quality control:</b> Autophagy removes junk from cells, allowing them to function properly.</li>
                  <li><b>Cell survival:</b> Autophagy helps cells survive by removing damaged parts and recycling them for new cell parts.</li>
                </ul>
                
                <h2>Health Benefits of Fasting-Induced Autophagy</h2>
                
                <h3>Metabolic Health:</h3>
                <ul>
                  <li>Improves insulin sensitivity.</li>
                  <li>Reduces risk of type 2 diabetes.</li>
                </ul>
                
                <h3>Brain Health:</h3>
                <ul>
                  <li>Stimulates brain-derived neurotrophic factor (BDNF), promoting neural growth.</li>
                  <li>Protects against cognitive decline.</li>
                </ul>
                
                <h3>Anti-Aging:</h3>
                <ul>
                  <li>Reduces oxidative stress and inflammation.</li>
                  <li>Encourages repair mechanisms, slowing aging at the cellular level.</li>
                </ul>
                
                <h3>Cancer Prevention:</h3>
                <ul>
                  <li>Removes potentially cancerous cells.</li>
                  <li>Enhances the effectiveness of chemotherapy (when combined strategically).</li>
                </ul>
                
                <h3>Immune Function:</h3>
                <ul>
                  <li>Rejuvenates the immune system by clearing out old cells and supporting the production of new immune cells.</li>
                </ul>
                
                <p>I fast every Monday, regularly.</p>
                <p>I also cleanse body once a year at Naturopathy center for 10 days stay with 2 days water fasting & 7 days of fruit fasting.</p>
                <p>It helps in removing toxic stuff from body</p>
                
                <a href="https://www.linkedin.com/posts/harshadshah1953_its-called-autophagy-it-occurs-when-you-activity-7286356686162812929-7z0o?utm_source=share&utm_medium=member_desktop">Click here for source</a>

            </div>

            <div>
                <div className='external-link' onClick={() => { window.open('https://drwilliamli.com/the-body-can-heal-itself/') }}>An introduction to the healing power of body by Dr. William Li</div>
                <div className='external-link' onClick={() => { window.open('https://drwilliamli.com/your-body-the-new-homeland-defense/') }}>Your Body: The New Homeland Defense by Dr. William Li</div>
                <div className='external-link' onClick={() => { window.open('https://www.bobandbrad.com/') }}>Internet's most famous physiotherapist</div>
            </div>
        </>
    )
}

export default HealthHome;
